import BaseAPI from './base';
import { AttachmentPostData } from 'types/attachments';
import { ApiPromise } from 'types';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.ATTACHMENTS;

export class AttachmentsAPI extends BaseAPI {
  protected getAttachmentUrl() {
    return `/${ROUTES.BASE}`;
  }

  public createAttachment(attachment: File): ApiPromise<AttachmentPostData> {
    const formData = new FormData();
    formData.append('files', attachment, attachment.name);
    return this.post(`${this.getAttachmentUrl()}`, ROUTES.CREATE_ATTACHMENT.BODY(formData));
  }

  public deleteAttachment(id: number): ApiPromise<{}> {
    return this.delete(`${this.getAttachmentUrl()}/${ROUTES.DELETE_ATTACHMENT.DYNAMIC_ROUTE(id)}`);
  }
}

export default AttachmentsAPI;
