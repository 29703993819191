import BaseAPI from './base';
import { AuthLogListData } from 'types/logs';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.LOGS;

class AuthLogsAPI extends BaseAPI {
  protected getAuthLogsUrl() {
    return ROUTES.AUTH_BASE;
  }

  public getLogList(params: CollectionQueryParams): ApiPromise<CollectionData<AuthLogListData>> {
    return this.get(`${this.getAuthLogsUrl()}/${ROUTES.GET_AUTH_LOG_LIST.ROUTE}?${toQueryString(params)}`);
  }

  public getLogsExcel() {
    return this.getBlob(
      `${this.getAuthLogsUrl()}`,
      ROUTES.GET_AUTH_LOGS_EXCEL.BLOB(),
    );
  }
}

export default AuthLogsAPI;
