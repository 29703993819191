import BaseAPI from './base';
import { MasqueradeData } from 'types/admin';
import { ApiPromise } from 'types';
import {
  ChangePasswordPostData,
  RequestChangePasswordApiResponse,
  SignInResponseData,
  EmailOtpApiResponsePayload,
  SubmitEmailOtpApiResponsePayload,
  TwoFAMethodsApiResponsePayload,
  TOtpApiResponsePayload,
  SgpassInitApiResponsePayload,
  SgpassStatusApiResponsePayload,
  TwoFAStatusApiResponsePayload,
} from 'types/auth';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.AUTH;
const TWO_FACTOR = ROUTES.TWO_FACTOR;
const SGPASS = ROUTES.SGPASS;

export class AuthAPI extends BaseAPI {
  protected getAuthUrl() {
    return `/${ROUTES.BASE}`;
  }

  protected getTwoFAUrl() {
    return `/${TWO_FACTOR.BASE}`;
  }

  protected getSgpassUrl() {
    return `/${SGPASS.BASE}`;
  }

  public signIn(login: string, password: string, rememberMe: boolean): ApiPromise<SignInResponseData> {
    return this.post(`${this.getAuthUrl()}/${ROUTES.SIGN_IN.ROUTE}`, ROUTES.SIGN_IN.BODY(login, password, rememberMe));
  }

  public getTwoFAMethods(): ApiPromise<TwoFAMethodsApiResponsePayload> {
    return this.get(`${this.getTwoFAUrl()}`);
  }

  public getTwoFAStatus(): ApiPromise<TwoFAStatusApiResponsePayload> {
    return this.get(`${this.getTwoFAUrl()}/${TWO_FACTOR.GET_STATUS.ROUTE}`);
  }

  public initSgpassAuth(): ApiPromise<SgpassInitApiResponsePayload> {
    return this.get(`${this.getSgpassUrl()}/${SGPASS.INIT_SGPASS_AUTH.ROUTE}`);
  }

  public getSgpassAuthStatus(
    code: string | null,
    state: string | null,
    redirectUri: string,
  ): ApiPromise<SgpassStatusApiResponsePayload> {
    return this.get(
      `${this.getSgpassUrl()}/${SGPASS.GET_SGPASS_AUTH_STATUS.ROUTE}`,
      SGPASS.GET_SGPASS_AUTH_STATUS.BODY(code, state, redirectUri),
    );
  }

  public getTOtp(): ApiPromise<{}> {
    return this.get(`${this.getTwoFAUrl()}/${TWO_FACTOR.GET_TOTP.ROUTE}`);
  }

  public submitTOtp(otpAttempt: string): ApiPromise<{}> {
    return this.post(`${this.getTwoFAUrl()}/${TWO_FACTOR.SUBMIT_TOTP.ROUTE}`, TWO_FACTOR.SUBMIT_TOTP.BODY(otpAttempt));
  }

  public generateTOtp(): ApiPromise<TOtpApiResponsePayload> {
    return this.get(`${this.getTwoFAUrl()}/${TWO_FACTOR.GENERATE_TOTP.ROUTE}`);
  }

  public sendOtp(action: string): ApiPromise<EmailOtpApiResponsePayload> {
    return this.post(`${this.getTwoFAUrl()}/${TWO_FACTOR.SEND_OTP.ROUTE}`, TWO_FACTOR.SEND_OTP.BODY(action));
  }

  public resendOtp(action: string): ApiPromise<EmailOtpApiResponsePayload> {
    return this.post(`${this.getTwoFAUrl()}/${TWO_FACTOR.RESEND_OTP.ROUTE}`, TWO_FACTOR.RESEND_OTP.BODY(action));
  }

  public submitOtp(otpAttempt: string, action: string): ApiPromise<SubmitEmailOtpApiResponsePayload> {
    return this.post(
      `${this.getTwoFAUrl()}/${TWO_FACTOR.SUBMIT_OTP.ROUTE}`,
      TWO_FACTOR.SUBMIT_OTP.BODY(otpAttempt, action),
    );
  }

  public signOut(): ApiPromise<{}> {
    return this.delete(`${this.getAuthUrl()}/${ROUTES.SIGN_OUT.ROUTE}`);
  }

  public forgotPassword(email: string, redirectUrl: string): ApiPromise<{}> {
    return this.post(
      `${this.getAuthUrl()}/${ROUTES.FORGET_PASSWORD.ROUTE}`,
      ROUTES.FORGET_PASSWORD.BODY(email, redirectUrl),
    );
  }

  public resetPassword(token: string, password: string, passwordConfirmation: string): ApiPromise<{}> {
    return this.put(
      `${this.getAuthUrl()}/${ROUTES.RESET_PASSWORD.ROUTE}`,
      ROUTES.RESET_PASSWORD.BODY(token, password, passwordConfirmation),
    );
  }

  public getChangePasswordToken(): ApiPromise<RequestChangePasswordApiResponse> {
    return this.get(`${this.getAuthUrl()}/${ROUTES.GET_CHANGE_PASSWORD_TOKEN.ROUTE}`);
  }

  public changePassword(userId: number, formValues: ChangePasswordPostData): ApiPromise<{}> {
    return this.put(
      `${this.getAuthUrl()}/${ROUTES.CHANGE_PASSWORD.DYNAMIC_ROUTE(userId)}`,
      ROUTES.CHANGE_PASSWORD.BODY(formValues),
    );
  }

  public masqueradeAs(data: MasqueradeData['masquerade_as_id']): ApiPromise<{}> {
    return this.post(`${this.getAuthUrl()}/${ROUTES.MASQUERADE_AS.ROUTE}`, ROUTES.MASQUERADE_AS.BODY(data));
  }

  public getCurrentUser(): ApiPromise<SignInResponseData> {
    return this.get(`${this.getAuthUrl()}/${ROUTES.GET_CURRENT_USER.ROUTE}`);
  }

  public unmasquerade(): ApiPromise<{}> {
    return this.post(`${this.getAuthUrl()}/${ROUTES.UNMASQUERADE.ROUTE}`);
  }
}

export default AuthAPI;
