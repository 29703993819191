import axios, { AxiosPromise, AxiosResponse, AxiosError } from 'axios';
import { ApiResponse, ApiPromise, StatusMessageType, GrapeApiError } from '../../types';
import { getFileDetails } from 'utilities/api_response';
import { extractTokenFromResponse, saveTokenInCookies } from 'utilities/token';

const DEFAULT_API_RESPONSE: ApiResponse<{}> = Object.freeze({
  code: -1,
  payload: {},
  messages: [
    {
      content: 'Request failed. Please check your Internet connection.',
      type: StatusMessageType.Error,
    },
  ],
  errors: {},
});
// Set to read from env var
const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}`,
});

client.defaults.xsrfCookieName = 'CSRF-TOKEN';
client.defaults.xsrfHeaderName = 'X-CSRF-Token';
client.defaults.withCredentials = true;

const blobClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_BASE_PATH}`,
  responseType: 'blob',
});

blobClient.defaults.xsrfCookieName = 'CSRF-TOKEN';
blobClient.defaults.xsrfHeaderName = 'X-CSRF-Token';
blobClient.defaults.withCredentials = true;

class BaseAPI {
  private clientGet<D>(url: string, params?: any): AxiosPromise<ApiResponse<D>> {
    return client.get(url, { params, ...this.getConfig() });
  }
  private clientPost<D>(url: string, data: any = {}, multipart: boolean = false): AxiosPromise<ApiResponse<D>> {
    return client.post(url, data, this.getConfig(multipart));
  }

  private clientPut<D>(url: string, data: any = {}): AxiosPromise<ApiResponse<D>> {
    return client.put(url, data, this.getConfig());
  }

  private clientPatch<D>(url: string, data: any = {}): AxiosPromise<ApiResponse<D>> {
    return client.patch(url, data, this.getConfig());
  }

  private clientDelete<D>(url: string): AxiosPromise<ApiResponse<D>> {
    return client.delete(url, this.getConfig());
  }

  /**
   * Performs an asynchronous HTTP GET request to the given URL.
   * @param url The resource upon which to apply the request.
   * @param params The URL parameters to be sent with the request.
   * @returns ApiPromise<D> A Promise that resolves to `ApiResponse<D>` if the
   *     request was successful, or rejects with an `ApiResponse<{}>` if the request fails.
   */
  protected get<D>(url: string, params?: any): ApiPromise<D> {
    return processRequest(url, this.clientGet(url, params));
  }

  protected getBlob(
    url: string,
    { mimeType, mimeSubtype }: { mimeType: string; mimeSubtype: string },
    params?: any,
  ): AxiosPromise<Blob> {
    const defaultParams = {
      headers: {
        // TODO: refactor all instances of mimeType/mimeSubtype to mimeType
        // i.e. combine mimeType and subtype
        // the reason for this refactor is that xlsx does not correspond to application/xlsx
        Accept: mimeType, // This is actually not used in Carecorner; see below for details
      },
    };
    return processBlobRequest(
      url,
      blobClient.get(`${url}.${mimeSubtype}`, {
        params,
        ...defaultParams,
      }),
    );
  }
  // Normally, rails will infer the response format from headers["Accept"], so if we provide something like
  // Accept: `text/html`, rails will map this to the symbol :html, and under respond_to, the format will be html.
  // However, under routes.rb, all resources under namespace api/v1 default to {format: json}. I could not find the
  // documentation or any other online sources, but apparently, this overrides whatever is placed in the headers.
  // The only way to specify the format is to add the relevant extension .[format] after the url. In this case,
  // this is accomplished by the expression `${url}.${mimeSubtype}`.
  // Examining rails routes, for example we see a route like /api/v1/admin/venues(.:format)
  // When we send an HTTP request to /api/v1/admin/venues.abc, we are effectively setting format to abc.

  // specifying the extension e.g. .xlsx should also be sufficient for grape API (lions)
  // extension takes priority over content negotitation via Accept header,
  // but it is good to have both

  /**
   * Performs an asynchronous HTTP POST request to the given URL.
   * @param url The resource upon which to apply the request.
   * @param data The data to be sent along with the request.
   * @returns ApiPromise<D> A Promise that resolves to `ApiResponse<D>` if the
   *     request was successful, or rejects with an `ApiResponse<{}>` if the request fails.
   */
  protected post<D>(url: string, data: any = {}, multipart: boolean = false): ApiPromise<D> {
    return processRequest(url, this.clientPost(url, data, multipart));
  }

  /**
   * Performs an asynchronous HTTP PUT request to the given URL.
   * @param url The resource upon which to apply the request.
   * @param data The data to be sent along with the request.
   * @returns ApiPromise<D> A Promise that resolves to `ApiResponse<D>` if the
   *     request was successful, or rejects with an `ApiResponse<{}>` if the request fails.
   */
  protected put<D>(url: string, data: any = {}): ApiPromise<D> {
    return processRequest(url, this.clientPut(url, data));
  }

  /**
   * Performs an asynchronous HTTP PATCH request to the given URL.
   * @param url The resource upon which to apply the request.
   * @param data The data to be sent along with the request.
   * @returns ApiPromise<D> A Promise that resolves to `ApiResponse<D>` if the
   *     request was successful, or rejects with an `ApiResponse<{}>` if the request fails.
   */
  protected patch<D>(url: string, data: any = {}): ApiPromise<D> {
    return processRequest(url, this.clientPatch(url, data));
  }

  /**
   * Performs an asynchronous HTTP DELETE request to the given URL.
   * @param url The resource upon which to apply the request.
   * @returns ApiPromise<D> A Promise that resolves to `ApiResponse<D>` if the
   *     request was successful, or rejects with an `ApiResponse<{}>` if the request fails.
   */
  protected delete<D>(url: string): ApiPromise<D> {
    return processRequest(url, this.clientDelete(url));
  }

  private getConfig(multipart: boolean = false) {
    if (multipart) {
      return {
        headers: {
          // Content-Type is set to undefined for the XHR to be sent as a multipart request,
          // so binary data can be sent successfully to the backend.
          'Content-Type': undefined,
          Accept: 'application/json',
          // ...fetchTokenFromCookies(),
        },
      };
    }
    return {
      headers: {
        Accept: 'application/json',
        // ...fetchTokenFromCookies(),
      },
    };
  }

  protected getAuthUrl() {
    return '/users';
  }

  protected extractToken(promise: AxiosPromise<ApiResponse<any>>): AxiosPromise<ApiResponse<any>> {
    return promise.then((response) => {
      const token = extractTokenFromResponse(response);
      saveTokenInCookies(token);
      return response;
    });
  }
}

function processRequest<D, M>(endpoint: string, promise: AxiosPromise<ApiResponse<D>>): ApiPromise<D, {}> {
  return promise
    .then((response: AxiosResponse) => {
      const apiResponse = response.data;
      if (process.env.NODE_ENV === 'development') {
        /* tslint:disable-next-line */
        console.info(`[API] ${apiResponse.code} ${endpoint} : ${getResponseMessages(apiResponse)}`);
      }
      return apiResponse;
    })
    .catch((error: AxiosError) => {
      const apiResponse: GrapeApiError | ApiResponse<{}> =
        error.response && error.response.data ? error.response.data : DEFAULT_API_RESPONSE;
      if ((apiResponse as any).error !== undefined) {
        alert((apiResponse as GrapeApiError).error);
      }
      if (process.env.NODE_ENV === 'development') {
        if ((apiResponse as any).error !== undefined) {
          console.error((apiResponse as GrapeApiError).error);
        } else {
          /* tslint:disable-next-line */
          console.error(
            `[API] ${(apiResponse as ApiResponse<{}>).code} ${endpoint} : ${getResponseMessages(
              apiResponse as ApiResponse<{}>,
            )}`,
          );
        }
      }
      throw apiResponse;
    });
}

function processBlobRequest(endpoint: string, promise: AxiosPromise<Blob>): AxiosPromise<Blob> {
  return promise
    .then((response: AxiosResponse) => {
      const { contentType, filename } = getFileDetails(response);
      if (process.env.NODE_ENV === 'development') {
        /* tslint:disable-next-line */
        console.info(`[API] Retrieved file ${filename} of type ${contentType} from ${endpoint}`);
      }
      return response;
    })
    .catch((error: AxiosError) => {
      const { response } = error;
      // Needs 2FA
      if (response?.status == 403) {
        throw {
          code: 403,
          messages: [{ content: 'This file requires 2FA authentication.', type: 1 }],
        };
      }
      const apiResponse: ApiResponse<{}> =
        response?.data || DEFAULT_API_RESPONSE;
      if (process.env.NODE_ENV === 'development') {
        /* tslint:disable-next-line */
        console.error(`[API] ${apiResponse.code} ${endpoint} : ${getResponseMessages(apiResponse)}`);
      }
      throw apiResponse;
    });
}

function getResponseMessages(response: ApiResponse<any>): string {
  return response.messages && response.messages.length > 0
    ? response.messages.map((message) => message.content).join(' : ')
    : '';
}

export default BaseAPI;
