import BaseAPI from './base';
import { ApiPromise } from 'types';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.CONSTANTS;

export class ConstantsAPI extends BaseAPI {
  protected getConstantsUrl() {
    return `/${ROUTES.BASE}`;
  }

  public getConstants(): ApiPromise<{ constants: string }> {
    return this.get(`${this.getConstantsUrl()}`);
  }
}

export default ConstantsAPI;
