import BaseAPI from './base';
import {
  IntakeListData,
  IntakeData,
  IntakePostData,
  IntakeActionsPostData,
  IntakeOutcomeNewData,
  IntakePromoteDecisionPostData,
  IntakeOutcomeApprovalPostData,
  IntakePatchData,
  IntakeSessionListData,
  IntakeSessionData,
  IntakeSessionPostData,
  IntakeSessionNewData,
  IntakeSessionAction,
  IntakeAssessmentPostData,
  IntakeAssessmentData,
} from 'types/intakes';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import {
  AttachmentData,
  AttachmentListData,
  AttachmentPostData,
  IntakeAttachmentPostData,
} from '../../types/attachments';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.INTAKES;

export class IntakesAPI extends BaseAPI {
  protected getIntakeUrl() {
    return ROUTES.BASE;
  }

  public getIntakeList(params: CollectionQueryParams): ApiPromise<CollectionData<IntakeListData>> {
    return this.get(`${this.getIntakeUrl()}?${toQueryString(params)}`);
  }

  public getIntake(id: number): ApiPromise<{ intake: IntakeData }> {
    return this.get(`${this.getIntakeUrl()}/${ROUTES.GET_INTAKE.DYNAMIC_ROUTE(id)}`);
  }

  public addIntake(data: IntakePostData): ApiPromise<{ intake: IntakeData }> {
    return this.post(`${this.getIntakeUrl()}`, ROUTES.ADD_INTAKE.BODY(data));
  }

  public getIntakeSessionList(
    params: CollectionQueryParams,
    intakeId: number,
  ): ApiPromise<CollectionData<IntakeSessionListData>> {
    return this.get(
      `${this.getIntakeUrl()}/${ROUTES.GET_INTAKE_SESSION_LIST.DYNAMIC_ROUTE(intakeId)}?${toQueryString(params)}`,
    );
  }

  public deleteIntakeSession(intakeId: number, sessionId: number): ApiPromise<{}> {
    return this.delete(`${this.getIntakeUrl()}/${ROUTES.DELETE_INTAKE_SESSION.DYNAMIC_ROUTE(intakeId, sessionId)}`);
  }

  public getIntakeSession(intakeId: number, sessionId: number): ApiPromise<IntakeSessionData> {
    return this.get(`${this.getIntakeUrl()}/${ROUTES.GET_INTAKE_SESSION.DYNAMIC_ROUTE(intakeId, sessionId)}`);
  }

  public patchIntakeSessionState(
    intakeId: number,
    actableId: number,
    action: IntakeSessionAction,
    data?: IntakeSessionPostData,
  ): ApiPromise<{ session: IntakeSessionData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.PATCH_INTAKE_SESSION_STATE.DYNAMIC_ROUTE(intakeId, actableId, action)}`,
      ROUTES.PATCH_INTAKE_SESSION_STATE.BODY(data),
    );
  }

  public patchIntakeSession(intakeId: number, data: IntakeSessionPostData): ApiPromise<{ session: IntakeSessionData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.PATCH_INTAKE_SESSION.DYNAMIC_ROUTE(intakeId, data)}`,
      ROUTES.PATCH_INTAKE_SESSION.BODY(data),
    );
  }

  public postIntakeSession(intakeId: number, data: IntakeSessionNewData): ApiPromise<{ session: IntakeSessionData }> {
    return this.post(
      `${this.getIntakeUrl()}/${ROUTES.POST_INTAKE_SESSION.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.POST_INTAKE_SESSION.BODY(data),
    );
  }

  public getIntakeAttachmentList(
    params: CollectionQueryParams,
    intakeId: number,
  ): ApiPromise<CollectionData<AttachmentListData>> {
    return this.get(
      `${this.getIntakeUrl()}/${ROUTES.GET_INTAKE_ATTACHMENT_LIST.DYNAMIC_ROUTE(intakeId)}?${toQueryString(params)}`,
    );
  }

  public addIntakeAttachmentList(
    intakeId: number,
    attachmentData: IntakeAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentListData>> {
    return this.post(
      `${this.getIntakeUrl()}/${ROUTES.ADD_INTAKE_ATTACHMENT_LIST.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.ADD_INTAKE_ATTACHMENT_LIST.BODY(attachmentData),
    );
  }

  public editIntakeAttachment(
    intakeId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.EDIT_INTAKE_ATTACHMENT_LIST.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.EDIT_INTAKE_ATTACHMENT_LIST.BODY(attachmentData),
    );
  }

  public updateIntake(data: IntakePatchData): ApiPromise<{ intake: IntakeData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.UPDATE_INTAKE.DYNAMIC_ROUTE(data)}`,
      ROUTES.UPDATE_INTAKE.BODY(data),
    );
  }

  public postIntakeAction(id: number, data: IntakeActionsPostData): ApiPromise<{ intake: IntakeData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.POST_INTAKE_ACTION.DYNAMIC_ROUTE(id)}`,
      ROUTES.POST_INTAKE_ACTION.BODY(data),
    );
  }

  public addIntakeOutcome(intakeId: number, data: IntakeOutcomeNewData): ApiPromise<{ intake: IntakeData }> {
    return this.post(
      `${this.getIntakeUrl()}/${ROUTES.ADD_INTAKE_OUTCOME.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.ADD_INTAKE_OUTCOME.BODY(data),
    );
  }

  public approveIntakePromotion(
    intakeId: number,
    data: IntakePromoteDecisionPostData,
  ): ApiPromise<{ intake: IntakeData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.APPROVE_INTAKE_PROMOTION.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.APPROVE_INTAKE_PROMOTION.BODY(data),
    );
  }

  public makeIntakeOutcomeDecisionsLions(
    intakeId: number,
    data: IntakePromoteDecisionPostData,
  ): ApiPromise<{ intake: IntakeData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.MAKE_INTAKE_OUTCOME_DECISIONS_LIONS.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.MAKE_INTAKE_OUTCOME_DECISIONS_LIONS.BODY(data),
    );
  }

  public approveIntakeOutcome(
    intakeId: number,
    data: IntakeOutcomeApprovalPostData,
  ): ApiPromise<{ intake: IntakeData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.APPROVE_INTAKE_OUTCOME.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.APPROVE_INTAKE_OUTCOME.BODY(data),
    );
  }

  public patchIntakeAssessment(
    intakeId: number,
    data: IntakeAssessmentPostData,
  ): ApiPromise<{ assessment: IntakeAssessmentData }> {
    return this.patch(
      `${this.getIntakeUrl()}/${ROUTES.PATCH_INTAKE_ASSESSMENT.DYNAMIC_ROUTE(intakeId, data)}`,
      ROUTES.PATCH_INTAKE_ASSESSMENT.BODY(data),
    );
  }

  public postIntakeAssessment(
    intakeId: number,
    data: IntakeAssessmentPostData,
  ): ApiPromise<{ assessment: IntakeAssessmentData }> {
    return this.post(
      `${this.getIntakeUrl()}/${ROUTES.POST_INTAKE_ASSESSMENT.DYNAMIC_ROUTE(intakeId)}`,
      ROUTES.POST_INTAKE_ASSESSMENT.BODY(data),
    );
  }
}

export default IntakesAPI;
