export default {
  VIEWS: {
    ADMIN: {
      CLIENT: {
        DETAILS: {
          COMPONENTS: {
            personalParticularsSection: [
              'name',
              'traditionalName',
              'identityDocumentType',
              'identityDocument',
              'salutation',
              'dateOfBirth',
              'nationality',
              'placeOfBirth',
              'gender',
              'race',
              'religion',
              'ethnicity',
              'maritalStatus',
              'languages',
            ],
            contactInformationSection: ['contactDetails', 'addresses'],
            backgroundInformationSection: ['occupation', 'occupationStatus', 'highestEducationLevel', 'familyIncome'],
            socialSupportSection: ['nextOfKin'],
          },
          ENTRIES: [
            'personalParticularsSection',
            'contactInformationSection',
            'backgroundInformationSection',
            'socialSupportSection',
          ],
        },
      },
      STAFF: {
        COMPONENTS: {},
        ENTRIES: ['lionsStaffDetails', 'login'],
      },
    },
    CASE: {
      DETAILS: {
        COMPONENTS: {
          caseRemarksSection: ['caseRemarks', 'caseRemarksEditButton'],
          caseCalendarSection: ['caseCalendar'],
        },
        ENTRIES: [          
          'caseCalendarSection',
          'caseOverview',
          'caseRemarksSection',
          'caseIntSum',
          'caseReferralSources',
          'caseClientWorkersHistory',
          'caseRelatedCases',
          'caseLastModifiedStamp',
        ],
      },
      DETAILS_ASSESSMENT_INTERVENTION_SUMMARY: {
        COMPONENTS: {},
        ENTRIES: [],
      },
      DETAILS_CLIENTS_WORKER_HISTORY: {
        COMPONENTS: {
          caseworkersHistory: ['caseWorkers', 'canCarer', 'caseHistory'],
        },
        ENTRIES: ['caseworkersHistory'],
      },
      DETAILS_OTHER_CASEWORKER_REMARKS: {
        COMPONENTS: { otherCaseWorkerRemarks: [] },
        ENTRIES: ['otherCaseWorkerRemarks'],
      },
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          caseOverview: [
            'caseStatus',
            'zone',
            'caseRefNumber',
            'intakeDate',
            'openedDate',
            'closedDate',
            'caseClassification',
            'visitationFrequency',
            'reviewedOn',
            'validFrom',
            'plannedDate',
            'outstandingVisits',
            'suspensionDate',
            'unsuspensionDate',
            'closeVisitDate',
            'upcomingCaseStatus',
            'caseClassificationDetails',
            'visitationFrequencyDetails',
            'reviewedOnDetails',
            'validFromDetails',
          ],
        },
        ENTRIES: ['caseOverview'],
      },
      DETAILS_RELATED_CASES: {
        COMPONENTS: {},
        ENTRIES: [],
      },
      INTAKE_REFERRAL_SOURCES: {
        COMPONENTS: {},
        ENTRIES: ['sourceOfCaseAlwaysShow'],
      },
      SESSION_DETAILS: {
        fields: [
          'subject',
          'type',
          'visitorType',
          'sessionDateTime',
          'sessionStatus',
          'canCarers',
          'remarks',
          'contactType',
          'externalParty',
          'sessionType',
          'locationLions',
          'objective',
          'content',
          'observation',
          'intervention',
          'followUpPlan',
        ],
      },
      SESSION_INFO: {
        submitButtons: [
          'edit',
          'markDefaulted',
          'markAttended',
          'undoDefaulted',
          'undoAttended',
          'completeSession',
          'undoCompleted',
          'markDocumentationDone',
          'approve',
        ],
      },
      SHOW: {
        pages: [
          'generalInformation',
          'client',
          'sessions',
          'combinedAttachments',
          'temporalPermissions',
          'canCarers',
        ],
      },
      SUSPENSION_INFO: {
        SUSPENSION: {
          COMPONENTS: {},
          ENTRIES: ['caseSuspensionReason', 'caseSuspensionRemarks'],
        },
        UNSUSPENSION: {
          COMPONENTS: {},
          ENTRIES: ['caseUnSuspensionRemarks'],
        },
      },
    },
    INTAKE: {
      DETAILS_OVERVIEW: {
        COMPONENTS: {
          intakeOverview: ['zone', 'referralDate', 'closedDate', 'intakeStatus', 'assignedCaseworker', 'referralInfo'],
          manageIntake: ['assignCaseworker', 'manageIntakeButtons', 'goToCase'],
          intakeHistory: ['intakeAssessmentHistory', 'intakeRequestActivities', 'intakeWorkerHistory'],
        },
        ENTRIES: ['intakeOverview', 'intakeAssessment', 'manageIntake', 'intakeHistory'],
      },
    },
    APP_LAYOUT: {
      APP_BAR: {
        COMPONENTS: {
          main: ['accountButton'],
          dropdown: ['accountPicName', 'divider', 'customChangePassword', 'goToLegacy', 'signOut'],
        },
      },
      DRAWER: {
        COMPONENTS: {
          main: ['intake', 'cases', 'supervision', 'reportsExpand', 'reports', 'adminExpand', 'admin'],
          reports: ['epesHalfYearlyReport', 'csSummaryReport', 'csHomeVisitMonitoringReport'],
          admin: ['canCarers'],
          adminPermissions: ['clients', 'staffs'],
        },
      },
    },
  },
};
