import BaseAPI from './base';
import { LogListData } from 'types/logs';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.LOGS;

class LogsAPI extends BaseAPI {
  protected getLogsUrl() {
    return ROUTES.BASE;
  }

  public getLogList(params: CollectionQueryParams): ApiPromise<CollectionData<LogListData>> {
    return this.get(`${this.getLogsUrl()}/${ROUTES.GET_LOG_LIST.ROUTE}?${toQueryString(params)}`);
  }

  public getLogsExcel() {
    return this.getBlob(`${this.getLogsUrl()}/${ROUTES.GET_LOGS_EXCEL.ROUTE}`, ROUTES.GET_LOGS_EXCEL.BLOB());
  }
}

export default LogsAPI;
