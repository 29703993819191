export default {
  FRONTEND_ROUTE: [
    'taskIndex',

    'changePassword',

    // Intakes routes
    'intakeIndex',
    'intakeSessionNew',
    'intakeSessionShow',
    'intakeShow',

    // Cases routes
    'caseIndex',
    'caseSessionNew',
    'caseSessionShow',
    'caseReviewNew',
    'caseReviewShow',
    'caseTypeChangeNew',
    'caseSuspensionNew',
    'caseTransferNew',
    'caseClosureNew',
    'caseReopenNew',
    'caseFeedbackNew',
    'caseFeedbackShow',
    'caseRequestShow',
    'caseShow',

    // Supervision routes
    'supervisionSessionNew',
    'supervisionSupervisorIndex',
    'supervisionSuperviseeIndex',
    'supervisionSessionClinicalLogNew',
    'supervisionSessionClinicalLogShow',
    'supervisionSessionCaseReviewNew',
    'supervisionSessionCaseReviewShow',
    'supervisionSessionShow',

    // Admin routes
    'clientIndexWithPermissionCheck',
    'clientNew',
    'clientShow',
    'staffIndexWithPermissionCheck',
    'staffNew',
    'staffShow',
    'canCarerIndex',
    'venueIndex',
    'supervisionIndex',
    'supervisionShow',

    // Report routes
    'epesReport',
    'epesReportCaseTable',
    'csSummaryReport',
    'csSummaryReportCaseTable',
    'csSummaryReportIntakeTable',
    'csHomeVisitMonitoringReport',
  ],
};
