import BaseAPI from './base';
import { ApiPromise, CollectionQueryParams } from 'types';
import { DateRange } from 'components/forms/fields/DateRangeField';
import {
  CaseMissingPrimaryProblemReportApiResponsePayload,
  CaseSpecialReportApiResponsePayload,
  CaseSummaryReportApiResponsePayload,
  CCSummaryReportApiResponsePayload,
  ClientSpecialReportApiResponsePayload,
  ClientProfileReportApiResponsePayload,
  ClosedCaseReportApiResponsePayload,
  CounsellorWorkloadReportApiResponsePayload,
  CSHomeVisitMonitoringReportApiResponsePayload,
  CSSummaryReportApiResponsePayload,
  DailyAppointmentReportApiResponsePayload,
  EpesReportApiResponsePayload,
  IndividualCcCaseworkerReportApiResponsePayload,
  IndividualCounsellorReportApiResponsePayload,
  IndividualSESVCaseworkerReportApiResponsePayload,
  IndividualPathsCaseworkerReportApiResponsePayload,
  IndividualCciCaseworkerReportApiResponsePayload,
  IndividualProgrammesPerformanceReportApiResponsePayload,
  IntakeSpecialReportApiResponsePayload,
  NumberOfSessionsReportApiResponsePayload,
  SESVSummaryReportApiResponsePayload,
  SupervisionSessionReportApiResponsePayload,
  WeeklySessionReportApiResponsePayload,
  DormantCaseReportApiResponsePayload,
  StaffOverviewReportApiResponsePayload,
} from 'types/reports';
import { AxiosPromise } from 'axios';
import { toQueryString } from 'utilities/url';
import { CollectionOptions } from 'types/store';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.REPORTS;

export class ReportsAPI extends BaseAPI {
  protected getReportUrl() {
    return `${ROUTES.BASE}`;
  }

  public getWeeklySessionReport(
    dateRange: DateRange,
    centreId: number | undefined,
  ): ApiPromise<WeeklySessionReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.WEEKLY_SESSION_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.WEEKLY_SESSION_REPORT.BODY(centreId),
    );
  }

  public getWeeklySessionReportExcel(dateRange: DateRange, centreId: number | undefined): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.WEEKLY_SESSION_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.WEEKLY_SESSION_REPORT.BLOB(),
      ROUTES.WEEKLY_SESSION_REPORT.BODY(centreId),
    );
  }

  public getStaffOverviewReport(dateRange: DateRange): ApiPromise<StaffOverviewReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.STAFF_OVERVIEW_REPORT.DYNAMIC_ROUTE(dateRange)}`);
  }

  public getStaffOverviewReportExcel(dateRange: DateRange): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.STAFF_OVERVIEW_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.STAFF_OVERVIEW_REPORT.BLOB(),
    );
  }

  public getCaseSummaryReport(
    dateRange: DateRange,
    caseCategoryId: number | undefined,
    case_type: { case_type_id: number | undefined },
    centreId: number | undefined,
    filterByReferralDate: number | undefined,
  ): ApiPromise<CaseSummaryReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CASE_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CASE_SUMMARY_REPORT.BODY(centreId, caseCategoryId, case_type, filterByReferralDate),
    );
  }

  public getCaseSummaryReportExcel(
    dateRange: DateRange,
    caseCategoryId: number | undefined,
    case_type: { case_type_id: number | undefined },
    centreId: number | undefined,
    filterByReferralDate: number | undefined,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CASE_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CASE_SUMMARY_REPORT.BLOB(),
      ROUTES.CASE_SUMMARY_REPORT.BODY(centreId, caseCategoryId, case_type, filterByReferralDate),
    );
  }

  public getNumberOfSessionsReport(selectDate: string): ApiPromise<NumberOfSessionsReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.NUMBER_OF_SESSIONS_REPORT.DYNAMIC_ROUTE(selectDate)}`);
  }

  public getNumberOfSessionsReportExcel(selectDate: string): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.NUMBER_OF_SESSIONS_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.NUMBER_OF_SESSIONS_REPORT.BLOB(),
    );
  }

  public getClosedCaseReport(dateRange: DateRange, centreId: number): ApiPromise<ClosedCaseReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CLOSED_CASE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CLOSED_CASE_REPORT.BODY(centreId),
    );
  }

  public getClosedCaseReportExcel(dateRange: DateRange, centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CLOSED_CASE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CLOSED_CASE_REPORT.BLOB(),
      ROUTES.CLOSED_CASE_REPORT.BODY(centreId),
    );
  }

  public getDormantCaseReport(selectDate: string): ApiPromise<DormantCaseReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.DORMANT_CASE_REPORT.DYNAMIC_ROUTE(selectDate)}`);
  }

  public getDormantCaseReportExcel(selectDate: string): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.DORMANT_CASE_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.DORMANT_CASE_REPORT.BLOB(),
    );
  }

  public getIndividualCounsellorSummaryReport(
    dateRange: DateRange,
    staffId: number | undefined,
    case_type: { case_type_id: number | undefined },
    centreId: number | undefined,
    filterByReferralDate: number | undefined,
  ): ApiPromise<IndividualCounsellorReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_COUNSELLOR_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_COUNSELLOR_REPORT.BODY(staffId, case_type, centreId, filterByReferralDate),
    );
  }

  public getIndividualCounsellorSummaryReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    case_type: { case_type_id: number | undefined },
    centreId: number | undefined,
    filterByReferralDate: number | undefined,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_COUNSELLOR_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_COUNSELLOR_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_COUNSELLOR_REPORT.BODY(staffId, case_type, centreId, filterByReferralDate),
    );
  }

  public getSupervisionSessionReport(
    dateRange: DateRange,
    centreId: number,
  ): ApiPromise<SupervisionSessionReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.SUPERVISION_SESSION_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISION_SESSION_REPORT.BODY(centreId),
    );
  }

  public getSupervisionSessionReportExcel(dateRange: DateRange, centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.SUPERVISION_SESSION_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISION_SESSION_REPORT.BLOB(),
      ROUTES.SUPERVISION_SESSION_REPORT.BODY(centreId),
    );
  }

  public getCounsellorWorkloadReport(
    selectDate: string,
    centreId: number,
  ): ApiPromise<CounsellorWorkloadReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.COUNSELLOR_WORKLOAD_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.COUNSELLOR_WORKLOAD_REPORT.BODY(centreId),
    );
  }

  public getCounsellorWorkloadReportExcel(selectDate: string, centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.COUNSELLOR_WORKLOAD_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.COUNSELLOR_WORKLOAD_REPORT.BLOB(),
      ROUTES.COUNSELLOR_WORKLOAD_REPORT.BODY(centreId),
    );
  }

  public getCaseMissingPrimaryProblemReport(
    params: CollectionQueryParams,
    centreId: number,
  ): ApiPromise<CaseMissingPrimaryProblemReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CASE_MISSING_PRIMARY_PROBLEM_REPORT.ROUTE}?${toQueryString(params)}`,
      ROUTES.CASE_MISSING_PRIMARY_PROBLEM_REPORT.BODY(centreId),
    );
  }

  public getCaseMissingPrimaryProblemReportExcel(centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CASE_MISSING_PRIMARY_PROBLEM_REPORT.ROUTE}`,
      ROUTES.CASE_MISSING_PRIMARY_PROBLEM_REPORT.BLOB(),
      ROUTES.CASE_MISSING_PRIMARY_PROBLEM_REPORT.BODY(centreId),
    );
  }

  public getClientSpecialReport(
    dateRange: DateRange,
    case_category: { case_category_id: number | undefined } | undefined,
    centreId: number,
  ): ApiPromise<ClientSpecialReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CLIENT_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CLIENT_SPECIAL_REPORT.BODY(case_category, centreId),
    );
  }

  public getClientSpecialReportExcel(
    dateRange: DateRange,
    case_category: { case_category_id: number | undefined } | undefined,
    centreId: number,
    unmasked = false,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CLIENT_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CLIENT_SPECIAL_REPORT.BLOB(),
      ROUTES.CLIENT_SPECIAL_REPORT.BODY(case_category, centreId, unmasked),
    );
  }

  public getCcClientProfileReport(
    dateRange: DateRange,
    centreId: number,
  ): ApiPromise<ClientProfileReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CC_CLIENT_PROFILE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CC_CLIENT_PROFILE_REPORT.BODY(centreId),
    );
  }

  public getCcClientProfileReportExcel(
    dateRange: DateRange,
    centreId: number,
    unmasked: boolean = false,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CC_CLIENT_PROFILE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CC_CLIENT_PROFILE_REPORT.BLOB(),
      ROUTES.CC_CLIENT_PROFILE_REPORT.BODY(centreId, unmasked),
    );
  }

  public getSesvClientProfileReport(
    dateRange: DateRange,
    centreId: number,
  ): ApiPromise<ClientProfileReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.SESV_CLIENT_PROFILE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SESV_CLIENT_PROFILE_REPORT.BODY(centreId),
    );
  }

  public getSesvClientProfileReportExcel(
    dateRange: DateRange,
    centreId: number,
    unmasked: boolean = false,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.SESV_CLIENT_PROFILE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SESV_CLIENT_PROFILE_REPORT.BLOB(),
      ROUTES.SESV_CLIENT_PROFILE_REPORT.BODY(centreId, unmasked),
    );
  }

  public getDailyAppointmentReport(
    selectDate: string,
    centreId: number,
  ): ApiPromise<DailyAppointmentReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.DAILY_APPOINTMENT_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.DAILY_APPOINTMENT_REPORT.BODY(centreId),
    );
  }

  public getDailyAppointmentReportExcel(selectDate: string, centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.DAILY_APPOINTMENT_REPORT.DYNAMIC_ROUTE(selectDate)}`,
      ROUTES.DAILY_APPOINTMENT_REPORT.BLOB(),
      ROUTES.DAILY_APPOINTMENT_REPORT.BODY(centreId),
    );
  }

  public getCaseSpecialReport(
    dateRange: DateRange,
    caseCategoryId: number | undefined,
    centreId: number,
  ): ApiPromise<CaseSpecialReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CASE_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CASE_SPECIAL_REPORT.BODY(caseCategoryId, centreId),
    );
  }

  public getCaseSpecialReportExcel(
    dateRange: DateRange,
    caseCategoryId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CASE_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CASE_SPECIAL_REPORT.BLOB(),
      ROUTES.CASE_SPECIAL_REPORT.BODY(caseCategoryId, centreId),
    );
  }

  public getIntakeSpecialReport(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): ApiPromise<IntakeSpecialReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INTAKE_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INTAKE_SPECIAL_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getIntakeSpecialReportExcel(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INTAKE_SPECIAL_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INTAKE_SPECIAL_REPORT.BLOB(),
      ROUTES.INTAKE_SPECIAL_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getIndividualCcCaseworkerReport(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): ApiPromise<IndividualCcCaseworkerReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_CC_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_CC_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualCcCaseworkerReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_CC_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_CC_CASEWORKER_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_CC_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualSESVCaseworkerReport(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): ApiPromise<IndividualSESVCaseworkerReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_SESV_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_SESV_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualSESVCaseworkerReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_SESV_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_SESV_CASEWORKER_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_SESV_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualPathsCaseworkerReport(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): ApiPromise<IndividualPathsCaseworkerReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_PATHS_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_PATHS_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualPathsCaseworkerReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_PATHS_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_PATHS_CASEWORKER_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_PATHS_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualCciCaseworkerReport(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): ApiPromise<IndividualCciCaseworkerReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_CCI_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_CCI_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualCciCaseworkerReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_CCI_CASEWORKER_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_CCI_CASEWORKER_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_CCI_CASEWORKER_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualProgrammesPerformanceReport(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): ApiPromise<IndividualProgrammesPerformanceReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT.BODY(staffId, centreId),
    );
  }

  public getIndividualProgrammesPerformanceReportExcel(
    dateRange: DateRange,
    staffId: number | undefined,
    centreId: number,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT.BLOB(),
      ROUTES.INDIVIDUAL_PROGRAMMES_PERFORMANCE_REPORT.BODY(staffId, centreId),
    );
  }

  public getCCSummaryReport(dateRange: DateRange, centreId: number): ApiPromise<CCSummaryReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.CC_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CC_SUMMARY_REPORT.BODY(centreId),
    );
  }

  public getCCSummaryReportExcel(dateRange: DateRange, centreId: number): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CC_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CC_SUMMARY_REPORT.BLOB(),
      ROUTES.CC_SUMMARY_REPORT.BODY(centreId),
    );
  }

  public getSESummaryReport(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): ApiPromise<SESVSummaryReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.SUPERVISED_EXCHANGE_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISED_EXCHANGE_SUMMARY_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getSESummaryReportExcel(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.SUPERVISED_EXCHANGE_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISED_EXCHANGE_SUMMARY_REPORT.BLOB(),
      ROUTES.SUPERVISED_EXCHANGE_SUMMARY_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getSVSummaryReport(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): ApiPromise<SESVSummaryReportApiResponsePayload> {
    return this.get(
      `${this.getReportUrl()}/${ROUTES.SUPERVISED_VISITATION_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISED_VISITATION_SUMMARY_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getSVSummaryReportExcel(
    dateRange: DateRange,
    centreId: number,
    filterByReferralDate: number | undefined,
  ): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.SUPERVISED_VISITATION_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.SUPERVISED_VISITATION_SUMMARY_REPORT.BLOB(),
      ROUTES.SUPERVISED_VISITATION_SUMMARY_REPORT.BODY(centreId, filterByReferralDate),
    );
  }

  public getEpesReport(dateRange: DateRange): ApiPromise<EpesReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.EPES_REPORT.DYNAMIC_ROUTE(dateRange)}`);
  }

  public getEpesReportExcel(dateRange: DateRange): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.EPES_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.EPES_REPORT.BLOB(),
    );
  }

  public getCSSummaryReport(dateRange: DateRange): ApiPromise<CSSummaryReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.CS_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`);
  }

  public getCSSummaryReportExcel(dateRange: DateRange): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CS_SUMMARY_REPORT.DYNAMIC_ROUTE(dateRange)}`,
      ROUTES.CS_SUMMARY_REPORT.BLOB(),
    );
  }

  public getCSHomeVisitMonitoringReport(
    dateRange: DateRange,
    params: CollectionOptions,
  ): ApiPromise<CSHomeVisitMonitoringReportApiResponsePayload> {
    return this.get(`${this.getReportUrl()}/${ROUTES.CS_HOME_VISIT_MONITORING_REPORT.ROUTE}?${toQueryString(params)}`);
  }

  public getCSHomeVisitMonitoringReportExcel(dateRange: DateRange, params: CollectionOptions): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CS_HOME_VISIT_MONITORING_REPORT.ROUTE}.xlsx?${toQueryString(params)}`,
      ROUTES.CS_HOME_VISIT_MONITORING_REPORT.BLOB(),
    );
  }

  public getCSCaseListExcel(params: {
    search: string | undefined;
    filters: { [key: string]: any } | undefined;
    should_mask_id: boolean;
  }): AxiosPromise<Blob> {
    return this.getBlob(
      `${this.getReportUrl()}/${ROUTES.CS_CASE_LIST.ROUTE}.xlsx?${toQueryString(params)}`,
      ROUTES.CS_CASE_LIST.BLOB(),
    );
  }
}

export default ReportsAPI;
