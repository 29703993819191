import { FieldDetails, FieldTypeEnum } from '../../utilities/constants/Forms';

export default {
  FORMS: {
    ADMIN: {
      STAFF: {
        EDIT: {
          COMPONENTS: {
            personalParticulars: ['name', 'email', 'idDocumentType', 'idDocument'],
          },
          ENTRIES: ['personalParticulars'],
        },
        LOGIN: {
          COMPONENTS: {},
          ENTRIES: ['username', 'password', 'passwordConfirmation', 'email', 'role', 'regions'],
        },
      },
    },
    CASE: {
      CASE_TRANSFER: {
        fields: [],
      },
      CASE_CLOSURE: {
        fields: [
          {
            name: 'intervention_summary',
            type: FieldTypeEnum.Freetext,
            label: 'Intervention Summary',
            index: 1,
            required: false,
            xs: 12,
            innerxs: 12,
          },
          {
            name: 'intervention_outcome',
            type: FieldTypeEnum.Freetext,
            label: 'Intervention Outcome',
            index: 2,
            required: false,
            xs: 12,
            innerxs: 12,
          },
          {
            name: 'casework_and_counselling_reason_for_case_closure',
            type: FieldTypeEnum.MRQ,
            label: 'Casework And Counselling Reason For Case Closure',
            index: 3,
            required: true,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 0,
                label: '1a) Goals Met: Client had come to terms with divorce ',
              },
              {
                value: 1,
                label: '1b) Goals Met: Client managed feelings of grief, loss or emotional distress',
              },
              {
                value: 2,
                label: '1c) Goals Met: Client was able to address family conflicts',
              },
              {
                value: 3,
                label: '1d) Goals Met: Client had coped with problems related to divorce',
              },
              {
                value: 4,
                label: '1e) Goals Met: Client had shown improvements in family relations',
              },
              {
                value: 5,
                label: '1f) Goals Met: Client had demonstrated better parenting skills',
              },
              {
                value: 6,
                label: '1g) Goals Met: Client had demonstrated ability to seek support',
              },
              {
                value: 7,
                label: '2) Client withdraws',
              },
              {
                value: 8,
                label: '3) Client is uncontactable',
              },
              {
                value: 9,
                label: '4) Client is referred to another FSC',
              },
              {
                value: 10,
                label: '5) Others',
              },
            ],
          },
          {
            name: 'supervised_visitation_exchange_reason_for_case_closure',
            type: FieldTypeEnum.MCQ,
            label: 'Supervised Visitation Exchange Reason For Case Closure',
            index: 4,
            required: true,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 0,
                label: '1) Completion of Order: Completed all the sessions',
              },
              {
                value: 1,
                label: '2) Incomplete Order',
              },
              {
                value: 2,
                label: '3) Incomplete Order - with good outcome',
              },
            ],
          },
          {
            name: 'relationship_between_parents',
            type: FieldTypeEnum.MCQ,
            label: 'Relationship Between Parents',
            index: 5,
            required: false,
            xs: 6,
            innerxs: 12,
            dependent: 4,
            condition: [0],
            options: [
              {
                value: 1,
                label: 'No',
              },
              {
                value: 2,
                label: 'Yes',
              },
            ],
          },
          {
            name: 'parent_child_interaction',
            type: FieldTypeEnum.MCQ,
            label: 'Parent Child Interaction',
            index: 6,
            required: false,
            xs: 6,
            innerxs: 12,
            dependent: 4,
            condition: [0],
            options: [
              {
                value: 1,
                label: 'No',
              },
              {
                value: 2,
                label: 'Yes',
              },
            ],
          },
          {
            name: 'reason_for_case_closure',
            type: FieldTypeEnum.MRQ,
            label: 'Reason For Case Closure',
            index: 7,
            required: true,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 0,
                label: 'Accomplished',
              },
              {
                value: 1,
                label: 'Reluctant to Proceed',
              },
              {
                value: 2,
                label: 'Lost Contact',
              },
              {
                value: 3,
                label: 'Passed On',
              },
              {
                value: 4,
                label: 'No Response',
              },
              {
                value: 5,
                label: 'Out of Scope',
              },
              {
                value: 6,
                label: 'Other Reasons',
              },
            ],
          },
          {
            name: 'case_closure_reason_remarks',
            type: FieldTypeEnum.Freetext,
            label: 'Case Closure Reason Remarks',
            index: 8,
            required: false,
            xs: 12,
            innerxs: 12,
          },
        ] as FieldDetails[],
        display: {
          '0': [1, 2, 3, 8],
          '1': [1, 2, 4, 5, 6, 8],
          '2': [1, 2, 7, 8],
          '3': [1, 2, 7, 8],
          '4': [1, 2, 7, 8],
          '5': [1, 2, 7, 8],
          '6': [1, 2, 7, 8],
        },
      },
      CASE_SUSPENSION: {
        fields: [],
      },
      CASE_REOPEN: {
        fields: [
          {
            name: 'case_reopen_reason_remarks',
            type: FieldTypeEnum.Freetext,
            label: 'Reason for Reopen',
            index: -1,
            required: false,
            xs: 12,
            innerxs: 12,
          },
        ] as FieldDetails[],
      },
      FEEDBACK: {
        fields: [
          {
            name: 'distressed',
            type: FieldTypeEnum.MCQ,
            label: '1. After counselling I feel less distressed',
            index: 1,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'understanding',
            type: FieldTypeEnum.MCQ,
            label: '2. After counselling I have gained more understanding of my situation',
            index: 2,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'helpless',
            type: FieldTypeEnum.MCQ,
            label: '3. After counselling I feel less helpless about my situation',
            index: 3,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'least_can_do',
            type: FieldTypeEnum.MCQ,
            label: '4. After counselling I know what is the least I can do about the situation',
            index: 4,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'relationship',
            type: FieldTypeEnum.MCQ,
            label:
              '5. After counselling I feel that my relationship with my spouse/family members has improved slightly',
            index: 5,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'understood',
            type: FieldTypeEnum.MCQ,
            label: '6. I feel understood by my counsellor',
            index: 6,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'willing_to_help',
            type: FieldTypeEnum.MCQ,
            label: '7. I feel that my counsellor is willing to help me',
            index: 7,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'able_to_help',
            type: FieldTypeEnum.MCQ,
            label: '8. I feel that my counsellor is able to help me',
            index: 8,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'satisfied_agency',
            type: FieldTypeEnum.MCQ,
            label: '9. I am satisfied with the service provided by this agency',
            index: 9,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'satisfied_personnel',
            type: FieldTypeEnum.MCQ,
            label: '10. I am satisfied with the service provided by this front desk personnel',
            index: 10,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'Strongly disagree',
              },
              {
                value: 2,
                label: 'Disagree',
              },
              {
                value: 3,
                label: 'Neutral',
              },
              {
                value: 4,
                label: 'Agree',
              },
              {
                value: 5,
                label: 'Strongly agree',
              },
            ],
          },
          {
            name: 'stay_contacted',
            type: FieldTypeEnum.MCQ,
            label:
              '11. I agree to be contacted one month after the final counselling session to offer update about my situation',
            index: 11,
            required: false,
            xs: 12,
            innerxs: 12,
            options: [
              {
                value: 1,
                label: 'No',
              },
              {
                value: 2,
                label: 'Yes',
              },
            ],
          },
        ] as FieldDetails[],
      },
      DETAILS: {
        COMPONENTS: {
          overviewSection: ['overviewDateOpened', 'overviewIntakeDate', 'overviewCaseRemarks'],
          caseClientsSection: [],
        },
        ENTRIES: ['overviewSection'],
      },
      EDIT_SESSION: {
        IS_LOCATION_REQUIRED: false,
        COMPONENTS: {
          canCarerSection: ['canCarers'],
          locationLions: ['locationText'],
          externalPartyComponent: ['externalParty'],
        },
        ENTRIES: [
          'subject',
          'visitorType',
          'contactType',
          'externalPartyComponent',
          'sessionType',
          'locationLions',
          'sessionDateTime',
          'canCarerSection',
          'objective',
          'content',
          'observation',
          'intervention',
          'followUpPlan',
          'bigRemarks',
          'attachments',
          'documentationDone',
        ],
      },
      NEW_SESSION: {
        IS_LOCATION_REQUIRED: false,
        COMPONENTS: {
          canCarerSection: ['canCarers'],
          locationLions: ['locationText'],
          externalPartyComponent: ['externalParty'],
        },
        ENTRIES: [
          'subject',
          'visitorType',
          'contactType',
          'externalPartyComponent',
          'sessionType',
          'locationLions',
          'sessionDateTime',
          'canCarerSection',
          'objective',
          'content',
          'observation',
          'intervention',
          'followUpPlan',
          'bigRemarks',
          'attachments',
          'documentationDone',
        ],
      },
      NEW_REVIEW: {
        COMPONENTS: {
          dateOfReviewSection: ['dateOfReview', 'lateReviewReason'],
        },
        ENTRIES: ['dateOfReviewSection', 'caseClassification', 'visitationFrequency', 'nextReviewDate', 'remarks'],
      },
      NEW_TEMPORAL_PERMISSION: {
        COMPONENTS: {},
        ENTRIES: ['staffAutocomplete1', 'startEndDate'],
      },
    },
    INTAKE: {
      DETAILS: {
        REFERRAL: {
          COMPONENTS: {},
          ENTRIES: [
            'referralType',
            'referralDate',
            'referrer',
            'referringOrganisation',
            'designation',
            'contact',
            'fax',
            'email',
            'remarks',
          ],
        },
        INFO: {
          COMPONENTS: {},
          ENTRIES: ['sourceOfCase'],
        },
      },
      DETAILS_OVERVIEW: {
        PROMOTE_CASE: {
          COMPONENTS: {},
          ENTRIES: ['promoteToCase', 'disabledDisplayId', 'dateOpened', 'remarks'],
        },
      },
      NEW_INTAKE: {
        COMPONENTS: {
          intakeInformationSection: [
            'intakeOfficer',
            'intakeDate',
            'assistantCounsellor',
            'counsellingFee',
            'caseType',
            'intakeRemarks',
          ],
          sourceOfCaseSection: ['referralSourcesAttrib'],
          clientInfoSection: ['clientAttrib'],
          presentingProbSection: ['presentingProb', 'problemDetails'],
          briefAssessSection: [
            'briefAssessTitle',
            'briefAssessSuicidal',
            'briefAssessRiskOfViolence',
            'briefAssessUnderPsychiatric',
            'briefAssessEmotionallyUnstableDetails',
            'briefAssessActionTakenDetails',
            'briefAssessNoteCaption',
          ],
          preferredContactTimeSection: ['preferredContactTime'],
          specialRequestSection: ['specialRequest', 'specialNeedsDetails'],
          actionTakenSection: ['initialActions', 'intialActionsDetails'],
          noAppointmentSection: ['noAppointmentReason', 'noAppointmentDetails'],
        },
        ENTRIES: [
          'intakeInformationSection',
          'sourceOfCaseSection',
          'clientInfoSection',
          'presentingProbSection',
          'briefAssessSection',
          'preferredContactTimeSection',
          'specialRequestSection',
          'actionTakenSection',
          'noAppointmentSection',
        ],
      },
    },
    STAFF: {
      NEW: {
        COMPONENTS: {
          personalParticulars: ['name', 'identityDocumentType', 'identityDocument'],
          loginAndAuthorization: [
            'username',
            'password',
            'passwordConfirmation',
            'loginAndAuthorizationEmail',
            'role',
            'regions',
          ],
        },
        ENTRIES: ['personalParticulars', 'loginAndAuthorization'],
      },
    },
    SUPERVISION: {
      CASE_REVIEW: {
        COMPONENTS: {
          discussionDuringReview: ['caseStatusField', 'actionField', 'issue', 'actionToBeTaken'],
          caseStatusField: [],
          actionField: [],
        },
        ENTRIES: ['discussionDuringReview'],
      },
      SESSION: {
        COMPONENTS: {
          sessionDetailsSection: ['sessionType', 'sessionStartEndDate'],
          sessionSupervisorSection: ['sessionSupervisor'],
          sessionSuperviseeSection: ['sessionSupervisee'],
        },
        ENTRIES: ['sessionDetailsSection', 'sessionSupervisorSection', 'sessionSuperviseeSection'],
      },
      CASE_REVIEW_DETAILS: {
        COMPONENTS: {
          discussionDuringReview: ['caseStatusField', 'actionField', 'issue', 'actionToBeTaken'],
          caseStatusField: [],
          actionField: [],
        },
        ENTRIES: ['discussionDuringReview'],
      },
    },
  },
};
