import * as React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

/**
 * Loading Indicator used when fetching constants
 */
const InitialLoadingIndicator: React.FC<{}> = () => {
  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <CircularProgress size="10rem" />
      </div>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography variant="h4">Hang on, we&#39;re setting up things for you</Typography>
      </div>
    </div>
  );
};

export default InitialLoadingIndicator;
