import BaseAPI from './base';
import {
  CaseListData,
  CaseData,
  CaseSessionData,
  CaseSessionListData,
  CaseReviewData,
  CaseReviewListData,
  TemporalPermissionListData,
  CasePostData,
  CaseRequestListData,
  CaseSessionPostData,
  CaseSessionNewData,
  CaseReviewPostData,
  CaseReviewNewData,
  TemporalPermissionPostData,
  CaseRequestData,
  CaseRequestTypeChangePostData,
  CaseRequestTypeChangeNewData,
  TemporalPermissionEditData,
  CaseRequestTransferNewData,
  CaseRequestTransferDefaultValueData,
  CaseRequestTransferPostData,
  CaseRequestClosureNewData,
  CaseRequestClosurePostData,
  CaseRequestReopenNewData,
  CaseRequestReopenPostData,
  CaseSessionConcernsPostData,
  CaseFeedbackNewPostData,
  CaseFeedbackData,
  CaseFeedbackPostData,
  CaseFeedbackListData,
  CaseRequestClosureAction,
  CaseSessionAction,
  CaseRequestTransferAction,
  CaseRequestReopenAction,
  CaseRequestTypeChangeAction,
  CasesGroupListData,
  CaseCanCarerListData,
  CaseCanCarerPostData,
  CanCarerVisitAdditionalDetail,
  CaseRequestSuspensionNewData,
  CaseRequestSuspensionAction,
  CaseRequestSuspensionPostData,
} from 'types/cases';
import {
  AttachmentListData,
  CaseAttachmentPostData,
  AttachmentData,
  CaseSessionAttachmentPostData,
  CaseReviewAttachmentPostData,
  CaseRequestAttachmentPostData,
  AttachmentPostData,
} from 'types/attachments';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import { SupervisionSessionCaseReviewData, SupervisionSessionCaseReviewListData, SupervisionSessionClinicalLogListData } from '../../types/supervision';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.CASES;

export class CasesAPI extends BaseAPI {
  protected getCaseUrl() {
    return `${ROUTES.BASE}`;
  }

  public getCaseList(params: CollectionQueryParams): ApiPromise<CollectionData<CaseListData>> {
    return this.get(`${this.getCaseUrl()}?${toQueryString(params)}`);
  }

  public getCase(id: number): ApiPromise<{ case: CaseData }> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE.DYNAMIC_ROUTE(id)}`);
  }

  public searchCaseList(query: string): ApiPromise<CollectionData<CaseListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.SEARCH_CASE_LIST.DYNAMIC_ROUTE(query)}`);
  }

  public getCaseSessionList(caseId: number): ApiPromise<CollectionData<CaseSessionListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_SESSION_LIST.DYNAMIC_ROUTE(caseId)}`);
  }

  public getCaseSession(caseId: number, sessionId: number): ApiPromise<CaseSessionData> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_SESSION.DYNAMIC_ROUTE(caseId, sessionId)}`);
  }

  public getCaseReviewList(caseId: number): ApiPromise<CollectionData<CaseReviewListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REVIEW_LIST.DYNAMIC_ROUTE(caseId)}`);
  }

  public getSupervisionCaseReview(caseId: number, reviewId: number): ApiPromise<SupervisionSessionCaseReviewData> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REVIEW.DYNAMIC_ROUTE(caseId, reviewId)}`);
  }

  public getCaseReview(caseId: number, reviewId: number): ApiPromise<CaseReviewData> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REVIEW.DYNAMIC_ROUTE(caseId, reviewId)}`);
  }

  public getCaseAttachmentList(
    params: CollectionQueryParams,
    id: number,
  ): ApiPromise<CollectionData<AttachmentListData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.GET_CASE_ATTACHMENT_LIST.DYNAMIC_ROUTE(id)}?${toQueryString(params)}`,
    );
  }

  public addCaseAttachmentList(
    caseId: number,
    attachmentData: CaseAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentListData>> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.ADD_CASE_ATTACHMENT_LIST.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.ADD_CASE_ATTACHMENT_LIST.BODY(attachmentData),
    );
  }

  public editCaseAttachment(
    caseId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.EDIT_CASE_ATTACHMENT.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.EDIT_CASE_ATTACHMENT.BODY(attachmentData),
    );
  }

  public getCaseTemporalPermissionList(
    id: number,
    params?: CollectionQueryParams,
  ): ApiPromise<CollectionData<TemporalPermissionListData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.GET_CASE_TEMPORAL_PERMISSION_LIST.DYNAMIC_ROUTE(id)}?${
        params ? toQueryString(params) : ''
      }`,
    );
  }

  public addTemporalPermission(
    caseId: number,
    temp_permission: TemporalPermissionPostData,
  ): ApiPromise<{ temp_permission: TemporalPermissionListData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.ADD_TEMPORAL_PERMISSION.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.ADD_TEMPORAL_PERMISSION.BODY(temp_permission),
    );
  }

  public patchTemporalPermission(
    caseId: number,
    permissionId: number,
    temp_permission: TemporalPermissionEditData,
  ): ApiPromise<{ temp_permission: TemporalPermissionListData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_TEMPORAL_PERMISSION.DYNAMIC_ROUTE(caseId, permissionId)}`,
      ROUTES.PATCH_TEMPORAL_PERMISSION.BODY(temp_permission),
    );
  }

  public deleteTemporalPermission(caseId: number, payloadId: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_TEMPORAL_PERMISSION.DYNAMIC_ROUTE(caseId, payloadId)}`);
  }

  public getCaseCanCarerList(
    id: number,
    params: CollectionQueryParams,
  ): ApiPromise<CollectionData<CaseCanCarerListData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.GET_CASE_CAN_CARER_LIST.DYNAMIC_ROUTE(id)}?${toQueryString(params)}`,
    );
  }

  public addCaseCanCarer(
    caseId: number,
    can_carer: CaseCanCarerPostData,
  ): ApiPromise<{ can_carer: CaseCanCarerListData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.ADD_CASE_CAN_CARER.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.ADD_CASE_CAN_CARER.BODY(can_carer),
    );
  }

  public patchCaseCanCarer(
    caseId: number,
    canCarerId: number,
    can_carer: CaseCanCarerPostData,
  ): ApiPromise<{ can_carer: CaseCanCarerListData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_CAN_CARER.DYNAMIC_ROUTE(caseId, canCarerId)}`,
      ROUTES.PATCH_CASE_CAN_CARER.BODY(can_carer),
    );
  }

  public deleteCaseCanCarer(caseId: number, payloadId: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_CASE_CAN_CARER.DYNAMIC_ROUTE(caseId, payloadId)}`);
  }

  public getCaseRequestsList(caseId: number): ApiPromise<CollectionData<CaseRequestListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REQUESTS_LIST.DYNAMIC_ROUTE(caseId)}`);
  }
  // This is actually case discussions, exclusive on Lion's backend
  public getCaseReviewListByCase(caseId: number): ApiPromise<CollectionData<SupervisionSessionCaseReviewListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REVIEW_LIST_BY_CASE.DYNAMIC_ROUTE(caseId)}`);
  }

  public getClinicalLogListByCase(caseId: number): ApiPromise<CollectionData<SupervisionSessionClinicalLogListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CLINICAL_LOG_LIST_BY_CASE.DYNAMIC_ROUTE(caseId)}`);
  }

  public getCaseRequest(caseId: number, requestId: number): ApiPromise<CaseRequestData> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_REQUEST.DYNAMIC_ROUTE(caseId, requestId)}`);
  }

  public patchCase(data: CasePostData): ApiPromise<{ case: CaseData }> {
    return this.patch(`${this.getCaseUrl()}/${ROUTES.PATCH_CASE.DYNAMIC_ROUTE(data)}`, ROUTES.PATCH_CASE.BODY(data));
  }

  public patchCaseSession(caseId: number, data: CaseSessionPostData): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_SESSION.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_SESSION.BODY(data),
    ); // id is actable_id
  }

  public patchCanCarerVisitDetails(
    caseId: number,
    caseVisitId: number,
    data: CanCarerVisitAdditionalDetail,
  ): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CAN_CARER_VISIT_DETAILS.DYNAMIC_ROUTE(caseId, caseVisitId)}`,
      ROUTES.PATCH_CAN_CARER_VISIT_DETAILS.BODY(data),
    );
  }

  public patchCaseReview(caseId: number, data: CaseReviewPostData): ApiPromise<{ review: CaseReviewData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REVIEW.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REVIEW.BODY(data),
    );
  }

  public patchSupervisionCaseReview(caseId: number, data: CaseReviewPostData): ApiPromise<{ review: SupervisionSessionCaseReviewData}> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REVIEW.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REVIEW.BODY(data),
    );
  }

  public patchCaseSessionState(
    caseId: number,
    actableId: number,
    action: CaseSessionAction,
    data?: CaseSessionPostData,
  ): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_SESSION_STATE.DYNAMIC_ROUTE(caseId, actableId, action)}`,
      ROUTES.PATCH_CASE_SESSION_STATE.BODY(data),
    );
  }

  public deleteCaseSession(caseId: number, payloadId: number): ApiPromise<{ case: CaseData }> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_CASE_SESSION.DYNAMIC_ROUTE(caseId, payloadId)}`);
  }

  public deleteCaseReview(caseId: number, payloadId: number): ApiPromise<{ case: CaseData }> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_REVIEW_SESSION.DYNAMIC_ROUTE(caseId, payloadId)}`);
  }

  public patchCaseRequestTypeChange(
    caseId: number,
    data: CaseRequestTypeChangePostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_TYPE_CHANGE.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_TYPE_CHANGE.BODY(data),
    );
  }

  public updateCaseSession(caseId: number, actableId: number, state: string): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(`${this.getCaseUrl()}/${ROUTES.UPDATE_CASE_SESSION.DYNAMIC_ROUTE(caseId, actableId, state)}`);
  }

  public updateCaseReview(caseId: number, actableId: number): ApiPromise<{ review: CaseReviewData }> {
    return this.patch(`${this.getCaseUrl()}/${ROUTES.UPDATE_REVIEW_SESSION.DYNAMIC_ROUTE(caseId, actableId)}`);
  }

  public patchCaseRequestTypeChangeState(
    caseId: number,
    requestId: number,
    action: CaseRequestTypeChangeAction,
    data?: CaseRequestTypeChangePostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_TYPE_CHANGE_STATE.DYNAMIC_ROUTE(caseId, requestId, action)}`,
      ROUTES.PATCH_CASE_REQUEST_TYPE_CHANGE_STATE.BODY(data),
    );
  }

  public postCaseSession(caseId: number, data: CaseSessionNewData): ApiPromise<{ session: CaseSessionData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_CASE_SESSION.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_CASE_SESSION.BODY(data),
    );
  }

  public postCaseReview(caseId: number, data: CaseReviewNewData): ApiPromise<{ review: CaseReviewData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_CASE_REVIEW.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_CASE_REVIEW.BODY(data),
    );
  }

  public postSupervisionCaseReview(caseId: number, data: CaseReviewNewData): ApiPromise<{ review: SupervisionSessionCaseReviewData}> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_CASE_REVIEW.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_CASE_REVIEW.BODY(data),
    );
  }

  public patchCaseRequestCaseDocumentationSession(
    caseId: number,
    requestId: number,
    data: CaseSessionNewData,
  ): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_CASE_DOCUMENTATION_SESSION.DYNAMIC_ROUTE(caseId, requestId)}`,
      ROUTES.PATCH_CASE_REQUEST_CASE_DOCUMENTATION_SESSION.BODY(data),
    );
  }

  public patchCaseSessionCaseDocumentationSession(
    caseId: number,
    sessionId: number,
    data: CaseSessionNewData,
  ): ApiPromise<{ session: CaseSessionData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_SESSION_CASE_DOCUMENTATION_SESSION.DYNAMIC_ROUTE(caseId, sessionId)}`,
      ROUTES.PATCH_CASE_SESSION_CASE_DOCUMENTATION_SESSION.BODY(data),
    );
  }

  public getCaseSessionAttachmentList(
    params: CollectionQueryParams,
    caseId: number,
    sessionId: number,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.CASE_SESSION_ATTACHMENT.DYNAMIC_ROUTE(caseId, sessionId)}?${toQueryString(
        params,
      )}`,
    );
  }

  public addCaseSessionAttachmentList(
    caseId: number,
    sessionId: number,
    attachmentData: CaseSessionAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_SESSION_ATTACHMENT.DYNAMIC_ROUTE(caseId, sessionId)}`,
      ROUTES.CASE_SESSION_ATTACHMENT.BODY(attachmentData),
    );
  }

  public editCaseSessionAttachment(
    caseId: number,
    sessionId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_SESSION_ATTACHMENT.DYNAMIC_ROUTE(caseId, sessionId)}`,
      ROUTES.CASE_SESSION_ATTACHMENT.BODY(attachmentData),
    );
  }

  public getCaseReviewAttachmentList(
    params: CollectionQueryParams,
    caseId: number,
    reviewId: number,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.CASE_REVIEW_ATTACHMENT.DYNAMIC_ROUTE(caseId, reviewId)}?${toQueryString(params)}`,
    );
  }

  public addCaseReviewAttachmentList(
    caseId: number,
    reviewId: number,
    attachmentData: CaseReviewAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_REVIEW_ATTACHMENT.DYNAMIC_ROUTE(caseId, reviewId)}`,
      ROUTES.CASE_REVIEW_ATTACHMENT.BODY(attachmentData),
    );
  }

  public editCaseReviewAttachment(
    caseId: number,
    reviewId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REVIEW_ATTACHMENT.DYNAMIC_ROUTE(caseId, reviewId)}`,
      ROUTES.CASE_REVIEW_ATTACHMENT.BODY(attachmentData),
    );
  }

  public getCaseRequestAttachmentList(
    params: CollectionQueryParams,
    caseId: number,
    requestId: number,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.get(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_ATTACHMENT.DYNAMIC_ROUTE(caseId, requestId)}?${toQueryString(
        params,
      )}`,
    );
  }

  public addCaseRequestAttachmentList(
    caseId: number,
    requestId: number,
    attachmentData: CaseRequestAttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_ATTACHMENT.DYNAMIC_ROUTE(caseId, requestId)}`,
      ROUTES.CASE_REQUEST_ATTACHMENT.BODY(attachmentData),
    );
  }

  public editCaseRequestAttachment(
    caseId: number,
    requestId: number,
    attachmentData: AttachmentPostData,
  ): ApiPromise<CollectionData<AttachmentData>> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_ATTACHMENT.DYNAMIC_ROUTE(caseId, requestId)}`,
      ROUTES.CASE_REQUEST_ATTACHMENT.BODY(attachmentData),
    );
  }

  public postCaseRequestTypeChange(
    caseId: number,
    data: CaseRequestTypeChangeNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_TYPE_CHANGE.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.CASE_REQUEST_TYPE_CHANGE.BODY(data),
    );
  }

  public getCaseRequestTransferInitialData(
    caseId: number,
  ): ApiPromise<{ request: CaseRequestTransferDefaultValueData }> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_TRANSFER.DYNAMIC_ROUTE(caseId)}`);
  }

  public postCaseRequestTransfer(
    caseId: number,
    data: CaseRequestTransferNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_CASE_REQUEST_TRANSFER.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_CASE_REQUEST_TRANSFER.BODY(data),
    );
  }

  public patchCaseRequestTransfer(
    caseId: number,
    data: CaseRequestTransferPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_TRANSFER.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_TRANSFER.BODY(data),
    );
  }

  public patchCaseRequestTransferNew(
    caseId: number,
    data: CaseRequestTransferNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_TRANSFER.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_TRANSFER.BODY(data),
    );
  }

  public patchCaseRequestTransferState(
    caseId: number,
    requestId: number,
    action: CaseRequestTransferAction,
    data?: CaseRequestTransferPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_TRANSFER_STATE.DYNAMIC_ROUTE(caseId, requestId, action)}`,
      ROUTES.CASE_REQUEST_TRANSFER_STATE.BODY(data),
    );
  }

  public postCaseRequestSuspension(
    caseId: number,
    data: CaseRequestSuspensionNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_SUSPENSION.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.CASE_REQUEST_SUSPENSION.BODY(data),
    );
  }

  public patchCaseRequestSuspensionNew(
    caseId: number,
    data: CaseRequestSuspensionNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_SUSPENSION_NEW.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.CASE_REQUEST_SUSPENSION_NEW.BODY(data),
    );
  }

  public patchCaseRequestSuspensionState(
    caseId: number,
    requestId: number,
    action: CaseRequestSuspensionAction,
    data?: CaseRequestSuspensionPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_SUSPENSION_STATE.DYNAMIC_ROUTE(caseId, requestId, action)}`,
      ROUTES.CASE_REQUEST_SUSPENSION_STATE.BODY(data),
    );
  }

  public patchCaseRequestSuspension(
    caseId: number,
    data: CaseRequestSuspensionPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_SUSPENSION.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_SUSPENSION.BODY(data),
    );
  }

  public postCaseRequestClosure(
    caseId: number,
    data: CaseRequestClosureNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_CLOSURE.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.CASE_REQUEST_CLOSURE.BODY(data),
    );
  }

  public patchCaseRequestClosureNew(
    caseId: number,
    data: CaseRequestClosureNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_CLOSURE_NEW.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.CASE_REQUEST_CLOSURE_NEW.BODY(data),
    );
  }

  public patchCaseRequestClosureState(
    caseId: number,
    requestId: number,
    action: CaseRequestClosureAction,
    data?: CaseRequestClosurePostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.CASE_REQUEST_CLOSURE_STATE.DYNAMIC_ROUTE(caseId, requestId, action)}`,
      ROUTES.CASE_REQUEST_CLOSURE_STATE.BODY(data),
    );
  }

  public patchCaseRequestClosure(
    caseId: number,
    data: CaseRequestClosurePostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_CLOSURE.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_CLOSURE.BODY(data),
    );
  }

  public postCaseRequestReopen(
    caseId: number,
    data: CaseRequestReopenNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_CASE_REQUEST_REOPEN.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_CASE_REQUEST_REOPEN.BODY(data),
    );
  }

  public patchCaseRequestReopenNew(
    caseId: number,
    data: CaseRequestReopenNewData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_REOPEN_NEW.DYANMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_REOPEN_NEW.BODY(data),
    );
  }

  public patchCaseRequestReopenState(
    caseId: number,
    requestId: number,
    action: CaseRequestReopenAction,
    data?: CaseRequestReopenPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_REOPEN_STATE.DYNAMIC_ROUTE(caseId, requestId, action)}`,
      ROUTES.PATCH_CASE_REQUEST_REOPEN_STATE.BODY(data),
    );
  }

  public patchCaseRequestReopen(
    caseId: number,
    data: CaseRequestReopenPostData,
  ): ApiPromise<{ request: CaseRequestData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_REQUEST_REOPEN.DYNAMIC_ROUTE(caseId, data)}`,
      ROUTES.PATCH_CASE_REQUEST_REOPEN.BODY(data),
    );
  }

  public deleteCaseClosure(caseId: number, payloadId: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_CASE_CLOSURE.DYNAMIC_ROUTE(caseId, payloadId)}`);
  }

  public updateCaseAndClients(
    caseId: number,
    combinedData: CaseSessionConcernsPostData,
  ): ApiPromise<{ case: CaseData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.UPDATE_CASE_AND_CLIENTS.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.UPDATE_CASE_AND_CLIENTS.BODY(combinedData),
    );
  }

  public getCaseFeedback(caseId: number, feedbackId: number): ApiPromise<CaseFeedbackData> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_FEEDBACK.DYNAMIC_ROUTE(caseId, feedbackId)}`);
  }

  public getCaseFeedbacksList(caseId: number): ApiPromise<CollectionData<CaseFeedbackListData>> {
    return this.get(`${this.getCaseUrl()}/${ROUTES.GET_CASE_FEEDBACKS_LIST.DYNAMIC_ROUTE(caseId)}`);
  }

  public postNewCaseFeedback(
    caseId: number,
    data: CaseFeedbackNewPostData,
  ): ApiPromise<{ feedback: CaseFeedbackData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_NEW_CASE_FEEDBACK.DYNAMIC_ROUTE(caseId)}`,
      ROUTES.POST_NEW_CASE_FEEDBACK.BODY(data),
    );
  }

  public patchCaseFeedback(caseId: number, data: CaseFeedbackPostData): ApiPromise<{ feedback: CaseFeedbackData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASE_FEEDBACK.DYNAMIC_ROUTE(caseId, data.case_questionnaires_feedback.id)}`,
      ROUTES.PATCH_CASE_FEEDBACK.BODY(data),
    );
  }

  public postNewCasesGroup(data: { case_ids: number[] }): ApiPromise<{ cases_group: CasesGroupListData }> {
    return this.post(
      `${this.getCaseUrl()}/${ROUTES.POST_NEW_CASES_GROUP.ROUTE}`,
      ROUTES.POST_NEW_CASES_GROUP.BODY(data),
    );
  }

  public patchCasesGroup(casesGroupId: number, data: { case_ids: number[] }): ApiPromise<{}> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.PATCH_CASES_GROUP.DYNAMIC_ROUTE(casesGroupId)}`,
      ROUTES.PATCH_CASES_GROUP.BODY(data),
    );
  }

  public deleteCasesGroup(casesGroupId: number): ApiPromise<{}> {
    return this.delete(`${this.getCaseUrl()}/${ROUTES.DELETE_CASES_GROUP.DYNAMIC_ROUTE(casesGroupId)}`);
  }

  public deleteCasesGroupCase(casesGroupId: number, caseId: number): ApiPromise<{}> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.DELETE_CASES_GROUP_CASE.DYNAMIC_ROUTE(casesGroupId)}`,
      ROUTES.DELETE_CASES_GROUP_CASE.BODY(caseId),
    );
  }

  public addCasesGroupCase(casesGroupId: number, caseId: number): ApiPromise<{ cases_group: CasesGroupListData }> {
    return this.patch(
      `${this.getCaseUrl()}/${ROUTES.ADD_CASES_GROUP_CASE.DYNAMIC_ROUTE(casesGroupId)}`,
      ROUTES.ADD_CASES_GROUP_CASE.BODY(caseId),
    );
  }
}

export default CasesAPI;
