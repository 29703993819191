import BaseAPI from './base';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import { TaskListData } from 'types/tasks';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.TASKS;
export class TasksAPI extends BaseAPI {
  protected getTaskUrl() {
    return `${ROUTES.BASE}`;
  }

  public getTaskList(params: CollectionQueryParams): ApiPromise<CollectionData<TaskListData>> {
    return this.get(`${this.getTaskUrl()}?${toQueryString(params)}`);
  }
}

export default TasksAPI;
