import BaseAPI from './base';
import { CollectionQueryParams, CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import { PublicActivityListData, PublicActivityMiniEntity } from 'types/public_activities';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.PUBLIC_ACTIVITIES;

export class PublicActivitiesAPI extends BaseAPI {
  protected getPublicActivityUrl() {
    return ROUTES.BASE;
  }

  public getPublicActivityList(params: CollectionQueryParams): ApiPromise<CollectionData<PublicActivityListData>> {
    return this.get(`${this.getPublicActivityUrl()}?${toQueryString(params)}`);
  }

  public getPublicActivityListPreview(params: string): ApiPromise<CollectionData<PublicActivityListData>> {
    const searchParam = { search: params };
    return this.get(`${this.getPublicActivityUrl()}?${toQueryString(searchParam)}`);
  }

  public markPublicActivityRead(id: number): ApiPromise<PublicActivityMiniEntity> {
    return this.post(`${this.getPublicActivityUrl()}/${ROUTES.MARK_PUBLIC_ACTIVITY_READ.DYNAMIC_ROUTE(id)}`);
  }
}

export default PublicActivitiesAPI;
