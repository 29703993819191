import Cookies from 'js-cookie';
import { Token } from '../types/token';
import { AxiosResponse } from 'axios';

export const extractTokenFromResponse = (res: AxiosResponse): Token => ({
  'access-token': res.headers['access-token'],
  client: res.headers.client,
  uid: res.headers.uid,
});

export const saveTokenInCookies = (token: Token) => {
  Cookies.set('_cvwo-sacnl_session', JSON.stringify(token));
};

export const fetchTokenFromCookies = (): Token | null => {
  const tokenString = Cookies.get('_cvwo-sacnl_session');
  if (!tokenString) return null;

  const token: Token = JSON.parse(tokenString);
  return {
    'access-token': token['access-token'],
    client: token.client,
    uid: token.uid,
  };
};

export const clearTokenInCookies = () => {
  Cookies.remove('_cvwo-sacnl_session');
};
