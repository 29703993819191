import CasesAPI from './cases';
import { AdminAPI } from './admin';
import EventsAPI from './events';
import AttachmentsAPI from './attachments';
import IntakesAPI from './intakes';
import AuthAPI from './auth';
import SupervisionAPI from './supervision';
import TasksAPI from './tasks';
import ReportsAPI from './reports';
import PublicActivitiesAPI from './public_activities';
import CalendarEventsAPI from './calendar_events';
import LogsAPI from './logs';
import AuthLogsAPI from './auth_logs'
import ConstantsAPI from './constants';

const api = {
  cases: new CasesAPI(),
  admin: new AdminAPI(),
  events: new EventsAPI(),
  attachments: new AttachmentsAPI(),
  intakes: new IntakesAPI(),
  auth: new AuthAPI(),
  supervision: new SupervisionAPI(),
  tasks: new TasksAPI(),
  reports: new ReportsAPI(),
  publicActivities: new PublicActivitiesAPI(),
  calendarEvents: new CalendarEventsAPI(),
  logs: new LogsAPI(),
  authLogs: new AuthLogsAPI(),
  constants: new ConstantsAPI(),
};

Object.freeze(api);

export default api;
