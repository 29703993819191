import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import api from 'adapter/api';
import { SET_CENTRE } from 'utilities/initialize_centre';
import InitialLoadingIndicator from 'components/content/InitialLoadingIndicator';

const LazyApp = React.lazy(() => {
  return import('./App');
});

api.constants
  .getConstants()
  .then((res: any) => {
    SET_CENTRE(res.payload.constants);
  })
  .finally(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Suspense fallback={<InitialLoadingIndicator />}>
          <LazyApp />
        </Suspense>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  });
