import BaseAPI from './base';
import {
  SupervisionSessionData,
  SupervisionSessionListData,
  SupervisionSessionPostData,
  SupervisionSessionCaseListData,
  SupervisionSessionClinicalLogData,
  SupervisionSessionCaseReviewData,
  SupervisionSessionCaseData,
  SupervisionSessionClinicalLogPostData,
  SupervisionSessionCaseReviewPostData,
  SupervisionSessionCaseReviewAction,
  SupervisionSessionCaseReviewNewData,
  SupervisionSessionCaseReviewDefaultValueData,
  SupervisionSessionClinicalLogNewData,
  SupervisionSessionClinicalLogDefaultValueData,
  SupervisionSessionCaseReviewListData,
  SupervisionSessionClinicalLogListData,
  SupervisionSessionParticipantData,
} from '../../types/supervision';
import { ApiPromise, CollectionData, CollectionQueryParams } from '../../types';
import { toQueryString } from '../../utilities/url';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.SUPERVISION;

export class SupervisionAPI extends BaseAPI {
  protected getSupervisionUrl() {
    return `${ROUTES.BASE}`;
  }

  protected getSupervisionSessionsParticipantUrl() {
    return `${ROUTES.BASE_ALT}`;
  }

  public getSupervisionSessionList(
    params: CollectionQueryParams,
  ): ApiPromise<CollectionData<SupervisionSessionListData>> {
    return this.get(`${this.getSupervisionUrl()}?${toQueryString(params)}`);
  }

  public getSupervisionSession(id: number): ApiPromise<{ session: SupervisionSessionData }> {
    return this.get(`${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION.DYNAMIC_ROUTE(id)}`);
  }

  public addSupervisionSession(session: SupervisionSessionPostData): ApiPromise<{ session: SupervisionSessionData }> {
    return this.post(`${this.getSupervisionUrl()}`, ROUTES.ADD_SUPERVISION_SESSION.BODY(session));
  }

  public patchSupervisionSession(
    id: number,
    session: SupervisionSessionPostData,
  ): ApiPromise<{ session: SupervisionSessionData }> {
    return this.patch(`${this.getSupervisionUrl()}/${id}`, ROUTES.PATCH_SUPERVISION_SESSION.BODY(session));
  }

  public deleteSupervisionSession(id: number): ApiPromise<{}> {
    return this.delete(`${this.getSupervisionUrl()}/${ROUTES.DELETE_SUPERVISION_SESSION.DYNAMIC_ROUTE(id)}`);
  }

  public getSupervisionSessionCaseList(sessionId: number): ApiPromise<CollectionData<SupervisionSessionCaseListData>> {
    return this.get(`${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CASE_LIST.DYNAMIC_ROUTE(sessionId)}`);
  }

  public getSupervisionSessionCaseReviewList(
    sessionId: number,
  ): ApiPromise<CollectionData<SupervisionSessionCaseReviewListData>> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CASE_REVIEW_LIST.DYNAMIC_ROUTE(sessionId)}`,
    );
  }

  public getSupervisionSessionClinicalLogList(
    sessionId: number,
  ): ApiPromise<CollectionData<SupervisionSessionClinicalLogListData>> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CLINICAL_LOG_LIST.DYNAMIC_ROUTE(sessionId)}`,
    );
  }

  public getSupervisionSessionCase(sessionId: number, caseId: number): ApiPromise<SupervisionSessionCaseData> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CASE.DYNAMIC_ROUTE(sessionId)}`,
      ROUTES.GET_SUPERVISION_SESSION_CASE.BODY(caseId),
    );
  }

  public getSupervisionSessionCaseReview(
    sessionId: number,
    caseReviewId: number,
  ): ApiPromise<SupervisionSessionCaseReviewData> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CASE_REVIEW.DYNAMIC_ROUTE(
        sessionId,
        caseReviewId,
      )}`,
    );
  }

  public getSupervisionSessionClinicalLog(
    sessionId: number,
    clinicalLogId: number,
  ): ApiPromise<SupervisionSessionClinicalLogData> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CLINICAL_LOG.DYNAMIC_ROUTE(
        sessionId,
        clinicalLogId,
      )}`,
    );
  }

  public deleteSupervisionSessionCaseReview(sessionId: number, caseReviewId: number): ApiPromise<{}> {
    return this.delete(
      `${this.getSupervisionUrl()}/${ROUTES.DELETE_SUPERVISION_SESSION_CASE_REVIEW.DYNAMIC_ROUTE(
        sessionId,
        caseReviewId,
      )}`,
    );
  }

  public getSupervisionSessionCaseReviewInitialData(
    sessionId: number,
    caseId: number,
  ): ApiPromise<SupervisionSessionCaseReviewDefaultValueData> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CASE_REVIEW_INITIAL_DATA.DYNAMIC_ROUTE(sessionId)}`,
      ROUTES.GET_SUPERVISION_SESSION_CASE_REVIEW_INITIAL_DATA.BODY(caseId),
    );
  }

  public postSupervisionSessionCaseReview(
    sessionId: number,
    data: SupervisionSessionCaseReviewNewData,
  ): ApiPromise<SupervisionSessionCaseReviewData> {
    return this.post(
      `${this.getSupervisionUrl()}/${ROUTES.POST_SUPERVISION_SESSION_CASE_REVIEW.DYNAMIC_ROUTE(sessionId)}`,
      ROUTES.POST_SUPERVISION_SESSION_CASE_REVIEW.BODY(data),
    );
  }

  public patchSupervisionSessionCaseReview(
    sessionId: number,
    caseReviewId: number,
    data?: SupervisionSessionCaseReviewPostData,
    action?: SupervisionSessionCaseReviewAction,
  ): ApiPromise<SupervisionSessionCaseReviewData> {
    return this.patch(
      `${this.getSupervisionUrl()}/${ROUTES.PATCH_SUPERVISION_SESSION_CASE_REVIEW.DYNAMIC_ROUTE(
        sessionId,
        caseReviewId,
        action,
      )}`,
      ROUTES.PATCH_SUPERVISION_SESSION_CASE_REVIEW.BODY(data),
    );
  }

  public deleteSupervisionSessionClinicalLog(sessionId: number, clinicalLogId: number): ApiPromise<{}> {
    return this.delete(
      `${this.getSupervisionUrl()}/${ROUTES.DELETE_SUPERVISION_SESSION_CLINICAL_LOG.DYNAMIC_ROUTE(
        sessionId,
        clinicalLogId,
      )}`,
    );
  }

  public getSupervisionSessionClinicalLogInitialData(
    sessionId: number,
    caseId: number,
  ): ApiPromise<SupervisionSessionClinicalLogDefaultValueData> {
    return this.get(
      `${this.getSupervisionUrl()}/${ROUTES.GET_SUPERVISION_SESSION_CLINICAL_LOG_INITIAL_DATA.DYNAMIC_ROUTE(
        sessionId,
      )}`,
      ROUTES.GET_SUPERVISION_SESSION_CLINICAL_LOG_INITIAL_DATA.BODY(caseId),
    );
  }

  public postSupervisionSessionClinicalLog(
    sessionId: number,
    data: SupervisionSessionClinicalLogNewData,
  ): ApiPromise<SupervisionSessionClinicalLogData> {
    return this.post(
      `${this.getSupervisionUrl()}/${ROUTES.POST_SUPERVISION_SESSION_CLINICAL_LOG.DYNAMIC_ROUTE(sessionId)}`,
      ROUTES.POST_SUPERVISION_SESSION_CLINICAL_LOG.BODY(data),
    );
  }

  public patchSupervisionSessionClinicalLog(
    sessionId: number,
    clinicalLogId: number,
    data: SupervisionSessionClinicalLogPostData,
  ): ApiPromise<SupervisionSessionClinicalLogData> {
    return this.patch(
      `${this.getSupervisionUrl()}/${ROUTES.PATCH_SUPERVISION_SESSION_CLINICAL_LOG.DYNAMIC_ROUTE(
        sessionId,
        clinicalLogId,
      )}`,
      ROUTES.PATCH_SUPERVISION_SESSION_CLINICAL_LOG.BODY(data),
    );
  }

  // For CCP only
  public getSupervisorByStaff(): ApiPromise<CollectionData<SupervisionSessionParticipantData>> {
    return this.get(`${this.getSupervisionSessionsParticipantUrl()}/${ROUTES.GET_BY_SUPERVISOR.ROUTE}`);
  }

  public getSuperviseeByStaff(): ApiPromise<CollectionData<SupervisionSessionParticipantData>> {
    return this.get(`${this.getSupervisionSessionsParticipantUrl()}/${ROUTES.GET_BY_SUPERVISEE.ROUTE}`);
  }
}

export default SupervisionAPI;
