import { CollectionData, ApiPromise } from 'types';
import { toQueryString } from 'utilities/url';
import BaseAPI from './base';
import {
  CalendarEventListData,
  CalendarEventNewData,
  CalendarEventData,
  CalendarEventPatchData,
} from 'types/calendar_events';
import { CalendarDateOptions } from 'modules/calendar_events/types';
import { apiRoutes } from 'utilities/initialize_centre';

const ROUTES = apiRoutes.CALENDAR_EVENTS;

export class CalendarEventsAPI extends BaseAPI {
  protected getCalendarEventUrl() {
    return `/${ROUTES.BASE}`;
  }

  public getCalendarEventList(params: CalendarDateOptions): ApiPromise<CollectionData<CalendarEventListData>> {
    return this.get(`${this.getCalendarEventUrl()}/${ROUTES.GET_CALENDAR_EVENT_LIST.ROUTE}?${toQueryString(params)}`);
  }

  public getCalendarEvent(id: number): ApiPromise<{ calendar_event: CalendarEventData }> {
    return this.get(`${this.getCalendarEventUrl()}/${ROUTES.GET_CALENDAR_EVENT.DYNAMIC_ROUTE(id)}`);
  }

  public addCalendarEvent(calEvent: CalendarEventNewData): ApiPromise<{ calendar_event: CalendarEventData }> {
    return this.post(`${this.getCalendarEventUrl()}`, ROUTES.ADD_CALENDAR_EVENT.BODY(calEvent));
  }

  public updateCalendarEvent(calEvent: CalendarEventPatchData): ApiPromise<{ calendar_event: CalendarEventData }> {
    return this.patch(
      `${this.getCalendarEventUrl()}/${ROUTES.UPDATE_CALENDAR_EVENT.DYNAMIC_ROUTE(calEvent)}`,
      ROUTES.UPDATE_CALENDAR_EVENT.BODY(calEvent),
    );
  }

  public deleteCalendarEvent(id: number): ApiPromise<{}> {
    return this.delete(`${this.getCalendarEventUrl()}/${ROUTES.DELETE_CALENDAR_EVENT.DYNAMIC_ROUTE(id)}`);
  }
}

export default CalendarEventsAPI;
